<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div style="padding: 20px; background-color: #fff">
      <a-button type="primary" style="margin-bottom: 15px" @click="newlyBuilt">
        <a-icon type="plus" />
        新建
      </a-button>
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="HeplTable"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <a slot="action" slot-scope="text, item">
          <a @click="editInfo(item)" style="margin-right: 8px">编辑</a>
          <a-popconfirm
            title="确认删除吗？"
            ok-text="是"
            cancel-text="否"
            @confirm="delInfo(item)"
          >
            <a style="margin-right: 8px">删除</a>
          </a-popconfirm>
        </a>
        <span slot="sort" slot-scope="text, item, index">
          <a
            v-if="index === 0 && pageNum == 1"
            @click="sortList('down', item)"
            style="margin-left: 5px"
            >下移</a
          >
          <a
            v-else-if="
              index === HeplTable.length - 1 &&
              pageNum == Math.ceil(pagination.total / pagination.pageSize)
            "
            @click="sortList('up', item)"
            >上移</a
          >
          <span v-else>
            <a @click="sortList('up', item)">上移</a>
            <a @click="sortList('down', item)" style="margin-left: 5px">下移</a>
          </span>
        </span>
        <span slot="icon" slot-scope="text, item, index">
          <img style="width: 50px; height: 50px" :src="text" />
        </span>
        <span slot="funcLocation" slot-scope="text, item, index">
          <span v-if="text === 'mina'">小程序原生开发</span>
          <span v-if="text === 'app'">APP</span>
          <span v-if="text === 'h5'">H5</span>
        </span>
        <span slot="isOpen" slot-scope="text, item, index">
          <a-switch
            v-model="item.isOpen !== 0"
            checked-children="开"
            default-checked
            un-checked-children="关"
            @click="AppListSwitch(item.isOpen === 1 ? 0 : 1, item, 'isOpen')"
          />
        </span>
        <span slot="isOpenMina" slot-scope="text, item, index">
          <a-switch
            v-model="item.isOpenMina !== 0"
            checked-children="开"
            default-checked
            un-checked-children="关"
            @click="
              AppListSwitch(item.isOpenMina === 1 ? 0 : 1, item, 'isOpenMina')
            "
          />
        </span>
        <span slot="isOpenCommunity" slot-scope="text, item, index">
          <a-switch
            v-model="item.isOpenCommunity !== 0"
            checked-children="开"
            default-checked
            un-checked-children="关"
            @click="
              AppListSwitch(
                item.isOpenCommunity === 1 ? 0 : 1,
                item,
                'isOpenCommunity'
              )
            "
          />
        </span>
      </a-table>
      <!-- table框的分页 -->
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="
            font-size: medium;
            color: #929292;
            margin-left: 3%;
            font-size: 14px;
            bottom: 3%;
          "
        >
          共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          v-model="pagination.current"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
    <!-- 新建抽屉 -->
    <a-drawer
      title="新增/修改"
      placement="right"
      :closable="true"
      :visible="newlyVisible"
      @close="onClose1"
      width="50%"
    >
      <a-form-model
        :model="addForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules"
        ref="form"
      >
        <a-form-model-item label="功能" prop="funcModuleId">
          <a-select
            label-in-value
            v-model="addForm.funcModuleId"
            placeholder="请选择"
            @change="typeChange"
          >
            <a-select-option
              v-for="item in SelectList"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          style="margin-bottom: -10px"
          label="图标"
          prop="icon"
        >
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 60%;
              margin-bottom: -10px;
            "
          >
            <div class="uploadImg">
              <input
                id="inputTest"
                style="cursor: pointer; width: 100px; height: 100px; opacity: 0"
                accept="image/*"
                type="file"
                @change="uploadPic('addIcon')"
                ref="addIcon"
              />
            </div>
            <div class="upload_image">
              <img
                alt=""
                v-if="addForm.icon"
                style="width: 95px; height: 95px"
                :src="addForm.icon"
              />
            </div>
          </div>
          <span style="font-size: 10px">注：尺寸 宽640，大小不超过1M</span>
        </a-form-model-item>
        <a-form-model-item label="功能名称" prop="funcName">
          <a-input placeholder="请输入" v-model="addForm.funcName"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="isOpen">
          <a-switch
            v-model="addForm.isOpen"
            checked-children="开"
            default-checked
            un-checked-children="关"
          />
        </a-form-model-item>
        <a-form-model-item label="是否推荐到小程序">
          <a-switch
            v-model="addForm.isOpenMina"
            checked-children="开"
            default-checked
            un-checked-children="关"
          />
        </a-form-model-item>
        <a-form-model-item label="是否推荐到社区">
          <a-switch
            v-model="addForm.isOpenCommunity"
            checked-children="开"
            default-checked
            un-checked-children="关"
          />
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="
            () => {
              this.newlyVisible = false;
              this.$refs.form.resetFields();
            }
          "
        >
          取消
        </a-button>
        <!-- 提交并更新简介 -->
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="submit"
          v-if="buttonType === 'new'"
        >
          确认
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          type="primary"
          @click="editData"
          v-if="buttonType === 'edit'"
        >
          确定
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
  getHomefunction,
  getFunctionModule,
  postHomefunction,
  putHomefunction,
  delHomefunction,
  moveHomefunction,
} from "@/service/HomeFunctionManagement";
import { update } from "@/utils/update";
import { putminaRecommendList } from "../../../service/document_api";
export default {
  data() {
    return {
      pageNum: 1,
      SelectList: [],
      addForm: {
        icon: "",
        funcLocation: "mina",
        isOpen: false,
        funcModuleId: undefined,
        funcName: "",
      },
      rules: {
        funcModuleId: [{ required: true, message: "请选择", trigger: "blur" }],
        funcName: [{ required: true, message: "请输入", trigger: "blur" }],
        icon: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      newlyVisible: false,
      timeVal: [],
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      columns: [
        {
          title: "图标",
          dataIndex: "icon",
          key: "icon",
          scopedSlots: { customRender: "icon" },
        },
        {
          title: "名称",
          dataIndex: "funcName",
          key: "funcName",
          scopedSlots: { customRender: "source" },
        },
        {
          title: "功能类型",
          dataIndex: "funcType",
          key: "funcType",
          scopedSlots: { customRender: "funcLocation" },
        },
        {
          title: "功能code/链接",
          dataIndex: "funcLinkCode",
          key: "funcLinkCode",
        },
        {
          title: "创建人",
          dataIndex: "createdByName",
          key: "createdByName",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          key: "createdTime",
        },
          {
          title: "是否发布到小程序",
          dataIndex: "isOpenMina",
          key: "isOpenMina",
          scopedSlots: { customRender: "isOpenMina" },
        },
        {
          title: "是否发布到社区",
          dataIndex: "isOpenCommunity",
          key: "isOpenCommunity",
          scopedSlots: { customRender: "isOpenCommunity" },
        },
        {
          title: "状态",
          dataIndex: "isOpen",
          key: "isOpen",
          scopedSlots: { customRender: "isOpen" },
        },
      
        {
          title: "排序",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "操作",
          fixed: "right",
          width: 150,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      HeplTable: [],
      StartTime: "",
      EndTime: "",
      buttonType: "",
      id: "",
    };
  },

  created() {
    this.getTable();
  },
  methods: {
    typeChange(label) {
      this.addForm.funcName = label.label;
    },
    // 排序
    async sortList(updown, item) {
      console.log("item", item);
      const response = await putminaRecommendList(updown, item.id);
      if (response.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    // 获取列表
    async getTable() {
      let data = {
        pageNo: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      };
      const response = await getHomefunction(data);
      if (response.code === 0) {
        this.HeplTable = response.data;
        this.pagination.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    // 新建
    newlyBuilt() {
      this.newlyVisible = true;
      this.GetSelectSalary();
      this.buttonType = "new";
    },
    //  关闭新建抽屉
    onClose1() {
      this.newlyVisible = false;
      this.$refs.form.resetFields();
      this.addForm = {
        icon: "",
        funcLocation: "mina",
        isOpen: false,
        funcModuleId: undefined,
      };
    },
    // 上传图片
    async uploadPic(type) {
      // 上传图片
      let file = this.$refs[type].files[0]; // 获取 input 中的图片文件
      if (file !== undefined) {
        if (file.size < 512000) {
          // 限制图片大小
          const image = await update(file); // 调用方法将本地图片上传到服务器 返回值为服务器处理后的图片地址
          if (image.code === 0) {
            this.addForm.icon = image.data.url;
            this.$message.success("图片上传成功！");
          } else {
            this.$message.error(image.message);
          }
        } else {
          this.$message.error("请上传小于500kb的图片");
        }
      }
      inputTest.value = "";
    },
    // 编辑
    editInfo(record) {
      this.newlyVisible = true;
      this.GetSelectSalary();
      this.addForm = JSON.parse(JSON.stringify(record)); //content和备注
      this.addForm.isOpen = this.addForm.isOpen == 0 ? false : true;
      // console.log(this.addForm.funcModuleId)
      this.addForm.funcModuleId = { key: record.funcModuleId };
      this.id = record.id;
      this.buttonType = "edit";
    },
    // 删除
    async delInfo(record) {
      const response = await delHomefunction(record.id);
      if (response.code === 0) {
        this.$message.success("删除成功");
        this.getTable();
      } else {
        this.$message.warning(response.message);
      }
    },
    // 状态开关
    async AppListSwitch(isOpen, record, name) {
      if (name == "isOpen") {
        const data = {
          id: record.id,
          isOpen: isOpen, //是否开启
        };
        const response = await putHomefunction(data);
        if (response.code === 0) {
          this.getTable();
          this.$message.success("操作成功");
        }
      }
      if (name == "isOpenMina") {
        const data = {
          id: record.id,
          isOpenMina: isOpen, //是否开启
        };
        const response = await putHomefunction(data);
        if (response.code === 0) {
          this.getTable();
          this.$message.success("操作成功");
        }
      }
      if (name == "isOpenCommunity") {
        const data = {
          id: record.id,
          isOpenCommunity: isOpen, //是否开启
        };
        const response = await putHomefunction(data);
        if (response.code === 0) {
          this.getTable();
          this.$message.success("操作成功");
        }
      }
    },
    // 下拉框接口（薪资）
    async GetSelectSalary() {
      this.SelectList = []; //置空不然一直叠加选项
      let data = {
        funcType: "mina",
        pageNo: 1,
        pageSize: 9999,
      };
      const response = await getFunctionModule(data);
      if (response.code === 0) {
        response.data.forEach((item) => {
          this.SelectList.push({
            name: item.funcName,
            id: item.id,
          });
        });
      } else {
        this.$message.warning(response.message);
      }
      let info = {
        funcType: "h5",
        pageNo: 1,
        pageSize: 9999,
      };
      const res = await getFunctionModule(info);
      if (res.code === 0) {
        res.data.forEach((item) => {
          this.SelectList.push({
            name: item.funcName,
            id: item.id,
          });
        });
      } else {
        this.$message.warning(res.message);
      }
    },
    // 新建后的提交按钮
    async submit() {
      this.$refs.form.validate(async (valid) => {
        // 表单校验
        if (valid) {
          const data = {
            funcModuleId: this.addForm.funcModuleId.key,
            funcName: this.addForm.funcName, //功能名称
            icon: this.addForm.icon, //功能链接
            isOpen: this.addForm.isOpen === true ? 1 : 0, //是否开启
            funcLocation: "mina", //功能使用位置，mina小程序
            isOpenMina: this.addForm.isOpenMina === true ? 1 : 0,
            isOpenCommunity: this.addForm.isOpenCommunity === true ? 1 : 0,
          };
          const response = await postHomefunction(data);
          if (response.code === 0) {
            this.getTable();
            this.$refs.form.resetFields();
            (this.addForm = {
              icon: "",
              funcLocation: "mina",
              isOpen: false,
              funcModuleId: undefined,
            }),
              (this.newlyVisible = false);
            this.$message.success("新增成功");
          }
          // console.log("表单",this.addForm)
        } else {
          this.$message.error("请填写完整");
        }
      });
    },
    // 提交编辑
    async editData() {
      this.$refs.form.validate(async (valid) => {
        // 表单校验
        if (valid) {
          const data = {
            id: this.id,
            funcModuleId: this.addForm.funcModuleId.key,
            funcName: this.addForm.funcName, //功能名称
            icon: this.addForm.icon, //功能链接
            isOpen: this.addForm.isOpen === true ? 1 : 0, //是否开启
            isOpenMina: this.addForm.isOpenMina === true ? 1 : 0,
            isOpenCommunity: this.addForm.isOpenCommunity === true ? 1 : 0,
          };
          const response = await putHomefunction(data);
          if (response.code === 0) {
            this.getTable();
            this.$refs.form.resetFields();
            (this.addForm = {
              icon: "",
              funcLocation: "mina",
              isOpen: false,
              funcModuleId: undefined,
            }),
              (this.newlyVisible = false);
            this.$message.success("修改成功");
          }
        } else {
          this.$message.error("请填写完整");
        }
      });
    },
    // 排序
    async sortList(updown, item) {
      console.log("item", item);
      const response = await moveHomefunction(updown, item.id);
      if (response.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
.uploadImg {
  border: #dddddd 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload_image {
  border: #dddddd 2px dashed;
  width: 100px;
  height: 100px;
}

::v-deep .ant-table-tbody > tr > td {
  padding: 10px 10px;
  overflow-wrap: break-word;
}
</style>
